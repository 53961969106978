<template>
  <div style="position: relative; background: #fff; margin-top: 15px;">
    <component :is="tab.component" :options="tab.componentOptions" v-if="tab?.component" :config="tab.config" @config-changed="tabConfigChanged" full-height="calc(100vh - 110px)" />

    <div v-if="accountInfo.user.edit_page" style="position: absolute; left: 300px; top: -45px;">
      <el-button type="text" @click="showForm_config">↓配置↓</el-button>
    </div>

    <config-form ref="configForm" />
  </div>
</template>


<script>
import _ from 'lodash'
import { mapState } from 'vuex';

import ConfigForm from './Form_config.vue';

export default {
  components: {
    ConfigForm,
  },
  data() {
    return {
      tabIndex: '0',
      tabCount: [],
      config: null,
    }
  },
  computed: {
    ...mapState(['accountInfo']),
    tabs() {
      return this.config?.tabs;
    },
    tab() {
      return this.tabs?.[this.tabIndex];
    },

    configKey() {
      return `pageConfig_${this.$route.path}`;
    },
  },
  watch: {
    '$route.path'() {
      this.init();
    }
  },

  created() {
    this.init();
  },

  methods: {
    init() {
      this.tabIndex = '0';
      this.tabCount = [];
      this.config = null;
      
      this.$api.executeArray(
        ['sys_params.list', { code: this.configKey }],
      ).then(([
        { list: [sys_params]},
      ]) => {
        this.config = JSON.parse((sys_params ? sys_params.value : null) || '{}');
      })
    },
    showForm_config() {
      this.$refs.configForm.show(this.config, {
        title: '配置',
        success: config => {
          this.config = config;
          this.$api.execute('sys_params.replace', { code: this.configKey, value: JSON.stringify(this.config) })
        }
      })
    },

    tabConfigChanged(config) {
      this.config = _.assign({
        tabs: _.map(this.config.tabs, (o, index) => index == this.tabIndex ? _.assign({}, o, { config }) : o)
      })
      this.$api.execute('sys_params.replace', { code: this.configKey, value: JSON.stringify(this.config) })
    },
  }
}
</script>

<style>

</style>